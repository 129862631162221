@font-face {
  font-family: 'Porpora-Regular';
  src: local('Porpora-Regular'), url(./fonts/Porpora-Regular.ttf) format('truetype');
}

#root {
  margin: 0;
  font-family: Porpora-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
